import trustLogo from '../trustMain.svg';
import trustPlanet from '../trustPlanet.svg';
import trustName from '../trustName.svg';
import Button from './Button';
import reviewImage from '../review.avif';
import certik from '../certik.svg';
import cure53 from '../cure53.svg';
import halborn from '../halborn.svg';
import kudelski from '../kudelski.svg';
import blueShield from '../blueShield.svg';
import lock from '../lock.svg';
import parcel from '../parcel.svg';
import warning from '../warning.svg';
import bug from '../bug.svg';
import ancila from '../ancilia.svg';
import binance from '../binance.svg';
import hashdit from '../hashdit.svg';
import keys from '../keys.svg';
import compLock from '../computerLock.svg';
import computer from '../computer.svg';
import shield2 from '../blueShield2.svg';
import hands from '../hands.svg';
import fc from '../facebook.svg';
import git from '../github.svg';
import ig from '../ig.svg';
import x from '../x.svg';
import discord from '../discord.svg';
import reddit from '../reddit.svg';
import tg from '../telegram.svg';
import apple from '../apple.svg';
import chrome from '../chrome.svg';
import android from '../android.svg';

const Main = () => {
    return (
        <div className="main">
            <div className="header">
                <img src={trustLogo} alt="trustLogo" className='trust-main-logo' />
            </div>
            <div className="body">
                <section className="first">
                    <div className="main-first-image">
                        <img src={trustName} className='trust-name' alt="trustName" />
                        <img src={trustPlanet} alt="trustPlanet" className="trust-planet" />
                        <Button />
                    </div>
                    <div className="main-text-first">
                        <h1 className='first-main-title'>First 5 checks are free!</h1>
                        <span className="first-main-span">By checking wallets, you protect yourself from scammers and stolen coins</span>
                    </div>
                </section>
                <section className="second">
                    <img src={reviewImage} alt="review" className="review" />
                    <div className="second-text">
                        <h2 className="second-title"> What do we offer? </h2>
                        <span className="second-text-span"> We screen crypto wallets and transactions to identify the source of funds and provide you with a detailed report. You can determine the risk score of any wallet to avoid problems with regulatory authorities and to protect yourself from illicit funds </span>
                        <Button />
                    </div>
                </section>
                <section className="third">
                    <h3 className="third-title"> Independently audited by industry leaders </h3>
                    <div className="third-images">
                        <img src={certik} alt="certik" className="certik" />
                        <img src={cure53} alt="cure" className="cure" />
                        <img src={halborn} alt="halborn" className="halborn" />
                        <img src={kudelski} alt="kudelski" className="kudelski" />
                    </div>
                </section>
                <section className="fourth">
                    <div className="first-part">
                        <h2 className="fourth-title"> AMLCheck is security</h2>
                        <span className="fourth-title-text"> Personal data is processed and stored in accordance with GDPR</span>
                    </div>
                    <div className="second-part">
                        <img src={blueShield} alt="blueShield" className="second-part-image" />
                        <div className="second-part-text">
                            <h2 className="second-part-title"> Why is it needed?</h2>
                            <span className="second-part-subtitle"> By receiving illicit funds, you risk having your money frozen. We will help you avoid this</span>
                            <Button />
                            <span className="second-part-additional-text"> Our professionals will help you deal with a high-risk score</span>
                        </div>
                    </div>
                    <div className="third-part">
                        <div className="third-part-item">
                            <div className="third-part-title-block">
                                <img src={lock} alt="lock" className="third-part-img" />
                                <h2 className="third-part-title"> 9,500,000+ </h2>
                            </div>
                            <span className="third-part-text"> Wallets contain stolen or dirty money </span>
                        </div>
                        <div className="third-part-item">
                            <div className="third-part-title-block">
                                <img src={parcel} alt="parcel" className="third-part-img" />
                                <h2 className="third-part-title"> $8,736,400 </h2>
                            </div>
                            <span className="third-part-text"> For 5 years of work, we were able to save clients from losses </span>
                        </div>
                        <div className="third-part-item">
                            <div className="third-part-title-block">
                                <img src={warning} alt="warning" className="third-part-img" />
                                <h2 className="third-part-title"> 27% </h2>
                            </div>
                            <span className="third-part-text"> Wallets we've checked were suspicious </span>
                        </div>
                        <div className="third-part-item">
                            <div className="third-part-title-block">
                                <img src={bug} alt="bug" className="third-part-img" />
                                <h2 className="third-part-title"> Protection against sanctioned wallets </h2>
                            </div>
                            <span className="third-part-text"> Check whether an address is included within sanction lists. Block sanctioned entities and protect your business from fines and license revocation </span>
                        </div>
                    </div>
                </section>
                <section className="third fifth">
                    <h3 className="third-title"> Our security partners </h3>
                    <div className="third-images">
                        <img src={ancila} alt="ancila" className="certik" />
                        <img src={binance} alt="binance" className="cure" />
                        <img src={hashdit} alt="hashdit" className="halborn" />
                    </div>
                </section>
                <section className="sixth">
                    <div className="sixth-title-block">
                        <h2 className="sixth-title"> Your right to privacy </h2>
                        <span className="sixth-subtitle"> We prioritize your right to privacy, using data responsibly to enhance your experience. </span>
                    </div>
                    <div className="sixth-item">
                        <h3 className="sixt-item-title"> Self-custody </h3>
                        <img src={keys} alt="keys" className="sixth-item-img" />
                        <span className="sixt-item-text"> Your keys, your crypto. We never access or store your private keys. </span>
                    </div>
                    <div className="sixth-item">
                        <h3 className="sixt-item-title"> Zero personal tracking </h3>
                        <img src={compLock} alt="compLock" className="sixth-item-img" />
                        <span className="sixt-item-text"> We don't track or store your personal info, such as IP or email address. </span>
                    </div>
                    <div className="sixth-item">
                        <h3 className="sixt-item-title"> No sign-up required </h3>
                        <img src={computer} alt="computer" className="sixth-item-img" />
                        <span className="sixt-item-text"> Check your wallet freely. No personal data required. </span>
                    </div>
                </section>
                <section className="seventh">
                    <div className="seventh-item">
                        <img src={shield2} alt="shield" className="seventh-image" />
                        <h3 className="seventh-title"> Discover more ways to keep your assets safe </h3>
                        <a href='https://community.trustwallet.com/t/how-to-spot-a-phishing-attack-protect-your-crypto/753663' className="seventh-button">Get safety tips</a>
                    </div>
                    <div className="seventh-item">
                        <img src={hands} alt="hands" className="seventh-image" />
                        <h3 className="seventh-title"> Check your assets for dirtiness </h3>
                        <Button />
                    </div>
                </section>
                <footer className="footer">
                    <div className="social-media">
                        <h3 className="social-title">Stay Connected:</h3>
                        <div className="social-links">
                            <a href="https://facebook.com/trustwalletapp" className="social-link">
                                <img src={fc} alt="facebook" className="social-image" />
                            </a>
                            <a href="https://github.com/trustwallet" className="social-link">
                                <img src={git} alt="github" className="social-image" />
                            </a>
                            <a href="https://instagram.com/trustwallet" className="social-link">
                                <img src={ig} alt="instagram" className="social-image" />
                            </a>
                            <a href="https://twitter.com/trustwallet" className="social-link">
                                <img src={x} alt="twitter" className="social-image" />
                            </a>
                            <a href="https://discord.gg/trustwallet" className="social-link">
                                <img src={discord} alt="discord" className="social-image" />
                            </a>
                            <a href="https://reddit.com/r/trustapp" className="social-link">
                                <img src={reddit} alt="reddit" className="social-image" />
                            </a>
                            <a href="https://t.me/trustwallet" className="social-link">
                                <img src={tg} alt="telegram" className="social-image" />
                            </a>
                        </div>
                    </div>
                    <div className="menu-links">
                        <div className="menu-item">
                            <h3 className="menu-item-title">Wallet</h3>
                            <div className="menu-item-links">
                                <a href="https://checktrustwallet.com/download" className="menu-item-link">Mobile App</a>
                                <a href="https://checktrustwallet.com/browser-extension" className="menu-item-link">Browser Extension</a>
                            </div>
                        </div>
                        <div className="menu-item">
                            <h3 className="menu-item-title">Features</h3>
                            <div className="menu-item-links">
                                <a href="https://checktrustwallet.com/buy-crypto" className="menu-item-link">Buy Crypto</a>
                                <a href="https://checktrustwallet.com/swap" className="menu-item-link">Swaps</a>
                                <a href="https://checktrustwallet.com/staking" className="menu-item-link">Stacking</a>
                                <a href="https://checktrustwallet.com/nft" className="menu-item-link">NFTs</a>
                                <a href="https://checktrustwallet.com/security" className="menu-item-link">Security</a>
                            </div>
                        </div>
                        <div className="menu-item">
                            <h3 className="menu-item-title">Build</h3>
                            <div className="menu-item-links">
                                <a href="https://developer.trustwallet.com/developer/" className="menu-item-link">Developer Docs</a>
                                <a href="https://developer.trustwallet.com/developer/wallet-core" className="menu-item-link">Wallet Core</a>
                                <a href="https://developer.trustwallet.com/developer/listing-new-dapps" className="menu-item-link">Submit dApp</a>
                                <a href="https://developer.trustwallet.com/developer/listing-new-assets" className="menu-item-link">Get assets listed</a>
                            </div>
                        </div>
                        <div className="menu-item">
                            <h3 className="menu-item-title">Support</h3>
                            <div className="menu-item-links">
                                <a href="https://community.trustwallet.com/c/helpcenter/8" className="menu-item-link">FAQ</a>
                                <a href="https://community.trustwallet.com/" className="menu-item-link">Community Forum</a>
                                <a href="mailto:support@trustamlscaner.com" className="menu-item-link">Contact Us</a>
                            </div>
                        </div>
                        <div className="menu-item">
                            <h3 className="menu-item-title">About</h3>
                            <div className="menu-item-links">
                                <a href="https://checktrustwallet.com/about-us" className="menu-item-link">About Us</a>
                                <a href="https://checktrustwallet.com/careers" className="menu-item-link">Careers</a>
                                <a href="https://checktrustwallet.com/press" className="menu-item-link">Press Kit</a>
                                <a href="https://checktrustwallet.com/terms-of-service" className="menu-item-link">Terms of Service</a>
                                <a href="https://checktrustwallet.com/privacy-policy" className="menu-item-link">Privacy Policy</a>
                                <a href="https://checktrustwallet.com/blog" className="menu-item-link">Blog</a>
                            </div>
                        </div>
                    </div>
                    <div className="download-links">
                        <h2 className="download-title">Download Trust Wallet</h2>
                        <span className="download-subtitle"> The most trusted & secure crypto wallet. </span>
                        <div className="download-items">
                            <a href="https://apps.apple.com/app/apple-store/id1288339409?mt=8" className="download-item">
                                Download for iOS <img src={apple} alt="apple" className="download-icon" />
                            </a>
                            <a href="https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph" className="download-item">
                                Download Extension <img src={chrome} alt="chrome" className="download-icon" />
                            </a>
                            <a href="https://checktrustwallet.com/download/apk" className="download-item">
                                Download APK <img src={android} alt="android" className="download-icon" />
                            </a>
                        </div>
                    </div>
                    <img src={trustLogo} alt="trust" className="trust-footer-logo" />
                </footer>
            </div>
        </div>
    );
}

export default Main;